import React, {useState, useEffect} from "react"
import Axios from "axios"
import './style.scss'
import * as styles from './confirmEmail.module.scss'
import Layout from '../components/layout'
import * as queryString from "query-string";

// markup
const ConfirmEmail = ({ location }) => {
  
  const [loaded, setLoaded] = useState(false);
  const [messStyle, setMessStyle] = useState(`${styles.message}`)
  const [mess, setMess] = useState([])


  useEffect(() => {

    const { c } = queryString.parse(location.search);
    const data = { 
			"confirmationCode": c,
		};
		const headers ={
			"Content-Type": 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
		}

		Axios.post('https://us-central1-ambarassed.cloudfunctions.net/newsletterConfirm ', {"data": data}, {
			headers: headers
		  })
		.then(res => {
			setMessStyle(`${styles.message} ${styles.success}`)
			setMess(["Email został potwierdzony!", "Dziękuję!"])
      setLoaded(true)
		})
		.catch(error => {
			setMessStyle(`${styles.message} ${styles.error}`)
			setMess([ "Błąd serwera!" , "Link potwierdzający jest niepoprawny." ])
			console.log(error);
      setLoaded(true)
		});



  }, []);

  return (
    <Layout pageTitle="Potwierdź Email">
    <div className={styles.row}>
        {!loaded ?
                  <h2 className={styles.loading}>Wczytywanie...</h2>
                :
                <div className={messStyle}>
                  <h2>{mess[0]}</h2>
                  <p>{mess[1]}</p>
                </div>
        }
    </div>
    </Layout>
  )
}

export default ConfirmEmail
